import React from 'react';

//import image01 from '../../assets/img/home/heads/image-1.jpg';
import image02 from '../../assets/img/home/heads/image-2.jpg';
import image03 from '../../assets/img/home/heads/image-3.jpg';
//import image04 from '../../assets/img/home/heads/image-4.jpg';
//import image05 from '../../assets/img/home/heads/image-5.jpg';
//import image06 from '../../assets/img/home/heads/image-6.jpg';
//import image07 from '../../assets/img/home/heads/image-7.jpg';
//import image08 from '../../assets/img/home/heads/image-8.jpg';
import imageGorgias from '../../assets/img/home/heads/image-gorgias.jpeg';
import imageJumbo from '../../assets/img/home/heads/image-jumbo.jpeg';
import imageAlison from '../../assets/img/home/heads/alison.jpg';
import imageTandem from '../../assets/img/home/heads/image-tandem.jpg';



/**
 * @typedef {Object} Testimony
 * 
 * @property {string} name
 * @property {string} job
 * @property {string} content
 * @property {Image} img
 */

/**
 * Home sections 
 * @constant
 * @name testimonies
 * @memberof HomeData
 * @type {Testimony[]}
 */ 
export const testimonies = [
  {
	  name: 'testimony_name_gorgias',
	  job: 'testimony_job_gorgias',
	  content: 'testimony_content_gorgias',
	  img: {
		  src: imageGorgias,
		  alt: 'Romain Lapeyre & Alex Plugaru'
	  }
  },
  {
	  name: 'testimony_name_jumbo',
	  job: 'testimony_job_jumbo',
	  content: 'testimony_content_jumbo',
	  img: {
		  src: imageJumbo,
		  alt: 'Pierre Valade'
	  }
  },
  {
	  name: 'testimony_name_sqreen',
	  job: 'testimony_job_sqreen',
	  content: 'testimony_content_sqreen',
	  img: {
		  src: imageAlison,
		  alt: 'Alison Eastaway'
	  }
  },
  {
	  name: 'testimony_name_eFounders',
	  job: 'testimony_job_eFounders',
	  content: 'testimony_content_eFounders',
	  img: {
		  src: image02,
		  alt: 'Thibaud Elziere'
	  }
  },
  {
	  name: 'testimony_name_tandem',
	  job: 'testimony_job_tandem',
	  content: 'testimony_content_tandem',
	  img: {
		  src: imageTandem,
		  alt: 'Vivy Chao'
	  }
  },
  {
	  name: 'testimony_name_meetic',
	  job: 'testimony_job_meetic',
	  content: 'testimony_content_meetic',
	  img: {
		  src: image03,
		  alt: 'Mickael Moyen-Pedone'
	  }
  }
  //,{
  //	name: 'testimony_name_blablacar',
  //	job: 'testimony_job_blablacar',
  //	content: 'testimony_content_blablacar',
  //	img: {
  //		src: image04,
  //		alt: 'Albane Hussenet'
  //	}
  //},{
  // 	name: 'testimony_name_numberly',
  // 	job: 'testimony_job_numberly',
  // 	content: 'testimony_content_numberly',
  // 	img: {
  // 		src: image05,
  // 		alt: 'Marguerite Chassaigne'
  // 	}
  // },{
  //	name: 'testimony_name_lifen',
  //	job: 'testimony_job_lifen',
  //	content: 'testimony_content_lifen',
  //	img: {
  //		src: image06,
  //		alt: 'Etienne Depaulis'
  //	}
  //},{
  //	name: 'testimony_name_doctrine',
  //	job: 'testimony_job_doctrine',
  //	content: 'testimony_content_doctrine',
  //	img: {
  //		src: image07,
  //		alt: 'Nicolas Bustamante'
  //	}
  //},{
  //	name: 'testimony_name_implicity',
  //	job: 'testimony_job_implicity',
  //	content: 'testimony_content_implicity',
  //	img: {
  //		src: image08,
  //		alt: 'Louis Pinot'
  //	}
  //}
];

const Arrow = ({className,onClick}) => {
	className = className || '';
	return (
		<button type="button"
			data-role="none" 
			onClick={onClick}
			className={`${className}`}></button>
	)
}

// Brand slider settings
export const testimoniesSettings = {
  arrows: true,
  prevArrow: <Arrow className = 'slick-prev'/>,
  nextArrow: <Arrow className = 'slick-next'/>,
  dots: false,
  infinite: true,
  draggable: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  cssEase: "linear",
  autoplay: true,
  autoplaySpeed: 3000
};
