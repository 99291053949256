import i18n from 'i18next'; 
import { initReactI18next } from 'react-i18next';
import React from 'react';

//import frButton from './fr/button.json';
import enButton from './en/button.json';

//import frNav from './fr/navigation.json';
import enNav from './en/navigation.json';

//import frHome from './fr/home.json';
import enHome from './en/home.json';

//import frTestimonies from './fr/testimonies.json';
import enTestimonies from './en/testimonies.json';

//import frScreens from './fr/screens.json';
import enScreens from './en/screens.json';

//import frPlatform from './fr/platform.json';
import enPlatform from './en/platform.json';

//import frAbout from './fr/about.json';
import enAbout from './en/about.json';

//import frTalents from './fr/talents.json';
import enTalents from './en/talents.json';

//import frTechnology from './fr/technology.json';
import enTechnology from './en/technology.json';

//import frForms from './fr/forms.json';
import enForms from './en/forms.json';

//import frSecurity from './fr/security.json';
import enSecurity from './en/security.json';

//import frPrivacy from './fr/privacy.json';
import enPrivacy from './en/privacy.json';

//import frLegalNotice from './fr/legalNotice.json';
import enLegalNotice from './en/legalNotice.json';

//import frNotFound from './fr/notFound.json';
import enNotFound from './en/notFound.json';

// Language context
const LangContext = React.createContext({});

/**
 * Initialize internationalization module
 * Languages file are imported here
 * @memberof FunctionsIndex
 * @param {String} initLang - default locale
 * @return {Object} i18n module
 */
function i18nInit(initLang) {
	i18n
		.use(initReactI18next)
		.init({
			resources: {
				fr: {
          // full en: HACK
					translation: {
						...enButton,
						...enNav,
						...enHome,
						...enTestimonies,
						...enScreens,
						...enPlatform,
						...enAbout,
						...enTalents,
						...enTechnology,
						...enForms,
						...enSecurity,
						...enPrivacy,
						...enLegalNotice,
						...enNotFound,
					}
				},
				en: {
					translation: {
						...enButton,
						...enNav,
						...enHome,
						...enTestimonies,
						...enScreens,
						...enPlatform,
						...enAbout,
						...enTalents,
						...enTechnology,
						...enForms,
						...enSecurity,
						...enPrivacy,
						...enLegalNotice,
						...enNotFound,
					}
				}
			},
			lng: initLang,
			fallbackLng: ['en'],
			interpolation: {
				escapeValue: false
			}
		});

	return i18n;
}

export { LangContext };
export { i18nInit };
