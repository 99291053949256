import img01 from '../../assets/img/talents/your-data-is-safe.svg';
import img02 from '../../assets/img/talents/brands.png';

import { 
	Wishes,
	//Corporate,
	Opportunity,
	Companies,
} from '../../components/platform/AppScreens';

/**
 * Platform sections 
 * @constant
 * @name platformSections
 * @memberof TalentsView
 * @type {HomeSection[]}
 */ 
export const talentsSections = [{
	id: 'TalentsSection01',
	title: 'talents_subtitle_03',
	subtitle: 'talents_section_01_title',
	content: 'talents_section_01_content',
	gridLeft: 'col-md-5 col-lg-5 push-lg-1 push-md-1',
	gridRight: 'col-md-5 col-lg-5 push-lg-2 push-md-2',
	ChildNode: Wishes,
// },{
// 	id: 'TalentsSection02',
// 	subtitle: 'talents_section_02_title',
// 	content: 'talents_section_02_content',
// 	gridLeft: 'col-md-5 col-lg-5',
// 	gridRight: 'col-md-5 pull-md-1 col-lg-5 pull-lg-1',
// 	ChildNode: Corporate,
},{
	id: 'TalentsSection02',
	subtitle: 'talents_section_03_title',
	content: 'talents_section_03_content',
	gridLeft: 'col-md-5 col-lg-5',
	gridRight: 'col-md-5 pull-md-1 col-lg-5 pull-lg-1',
	ChildNode: Opportunity
},{
	id: 'TalentsSection04',
	title: 'talents_section_04_title',
	content: 'talents_section_04_content',
	gridLeft: 'col-md-5 col-lg-5',
	gridRight: 'col-md-6 col-lg-6 pull-lg-1 pull-md-1',
	img: {
		src: img02,
		alt: 'brands'
	}
},{
	id: 'TalentsSection05',
	title: 'talents_subtitle_04',
	subtitle: 'talents_section_05_title',
	content: 'talents_section_05_content',
	gridLeft: 'col-md-5 col-lg-5 push-lg-1 push-md-1',
	gridRight: 'col-md-5 col-lg-5 push-lg-2 push-md-2',
	ChildNode: Companies
},{
	id: 'TalentsSection06',
	subtitle: 'talents_section_06_title',
	content: 'talents_section_06_content',
	gridLeft: 'col-md-5 col-lg-5',
	gridRight: 'col-md-6 col-lg-6 pull-lg-1 pull-md-1',
	img: {
		src: img01,
		alt: 'your data is safe'
	}
},{
	id: 'TalentsSection07',
	subtitle: 'talents_section_07_title',
	content: 'talents_section_07_content',
	gridLeft: 'col-md-5 col-lg-5 push-lg-1 push-md-1',
	gridRight: 'col-md-5 col-lg-5'
}];