/**
 * Home page
 * @namespace TalentsView
 */

import React, { useEffect } from "react";
import Slider from "react-slick";
import { Translation } from "react-i18next";

import { talentsSections } from "../config/talents/sections";
//import { testimonies, testimoniesSettings } from "../config/home/testimonies";
import { brands, brandsSettings } from "../config/home/brands";
import { displayBrand } from "./MainView";

import ReactImage from "../components/ReactImage";
//import TestimonySlider from "../components/home/TestimonySlider";
import HomeSection from "../components/home/HomeSection";

import { nl2br } from "../components/functions";

import headerImg from "../assets/img/talents/header-image.jpg";

import { ReactComponent as Chrono } from "../assets/img/talents/chrono.svg";
// import {ReactComponent as ChronoMobile} from '../assets/img/talents/chrono-mobile.svg'
import ChronoMobile from "../assets/img/talents/chrono-mobile.png";

/**
 * Display talent section
 * @function
 * @name displaySection
 * @memberof TalentsView
 * @param {HomeSection} props - section data
 * @param {Nubmer} i - index
 */
function displaySection(t, props, i) {
  const title = props.title ? t(props.title) : null;
  const subtitle = props.subtitle ? t(props.subtitle) : null;
  const content = nl2br(t(props.content));
  let button = null;
  if (props.button) {
    button = {
      ...props.button,
      text: t(props.button.text),
    };
  }

  return (
    <HomeSection
      key={props.id}
      className="section-platform"
      title={title}
      content={content}
      id={props.id}
      subtitle={subtitle}
      button={button}
      img={props.img}
      ChildNode={props.ChildNode}
      gridLeft={props.gridLeft}
      gridRight={props.gridRight}
    />
  );
}

/**
 * Page talents
 * @class
 * @name TalentsView
 * @memberof TalentsView
 * @return {ReactComponent}
 */
export default function () {
  // Body class
  useEffect(() => {
    document.body.classList.add("page-talents");
    const genericMetaDescription = document.querySelector("meta[name=description]");
    genericMetaDescription.remove();
    const talentsMetaDescription = document.createElement("meta");
    talentsMetaDescription.name = "description";
    talentsMetaDescription.content = "Fais-toi contacter par les meilleures équipes tech. Donne tes critères en 3 min et rejoins la startup de tes rêves.";
    document.getElementsByTagName("head")[0].appendChild(talentsMetaDescription);
    return () => {
      document.body.classList.remove("page-talents");
      document.getElementsByTagName("head")[0].append(genericMetaDescription);
      talentsMetaDescription.remove();
    };
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, []);

  return (
    <Translation>
      {(t) => (
        <div id="TalentsView">
          {/* Header */}
          <header id="TalentsHeader" className="corner">
            {/* <div className = 'wrapper'>
					<h1 className = 'platform-title push-lg-1 push-md-1'>
					{ nl2br(t('talents_title')) }
					</h1>
					</div> */}

            <section id="TalentsHeaderSection" className="section-talents">
              <div className="wrapper">
                <div className="grid-flex">
                  <div className="grid-flex__item col-md-6 col-lg-6">
                    <h2 className="section-talents__title home-title">
                      {t("talents_subtitle_01")}
                      <span>{t("talents_subtitle_02")}</span>
                    </h2>
                    <a
                      href="https://candidates.hiresweet.com"
                      className="hs-button--blue"
                    >
                      {t("talents_button")}
                    </a>
                  </div>
                  <div className="grid-flex__item col-md-6 col-lg-6">
                    <ReactImage
                      img={{
                        src: headerImg,
                        alt: "talents",
                      }}
                      className="section-platform__img"
                    />
                  </div>
                </div>
              </div>
            </section>

            {/* Brand slider */}
            <section id="TalentsBrands">
              <div className="home-brand">
                <h3 className="home-title--sub home-brand__title--pink">
                  {t("talents_brand_title")}
                </h3>
              </div>
              <div className="home-brand__slider--pink">
                <Slider {...brandsSettings}>{brands.map(displayBrand)}</Slider>
              </div>
            </section>
          </header>

          <section id="TalentsTakeBack" className="soft-bg">
            <div id="TalentsTakeBackBg">
              {talentsSections.slice(0, 2).map(displaySection.bind(null, t))}
            </div>

            <center>
              <a
                href="https://candidates.hiresweet.com"
                className="hs-button--pink"
              >
                {t("talents_section_01_button")}
              </a>
            </center>
          </section>

          <section id="TalentsHiw" className="soft-bg">
            {talentsSections.slice(2, 3).map(displaySection.bind(null, t))}
          </section>

          <section id="TalentsHereForYou" className="soft-bg">
            <div id="TalentsHereForYouBg">
              {talentsSections.slice(3, 6).map(displaySection.bind(null, t))}
              <div className="wrapper">
                <div className="push-lg-1 push-md-1 col-md-10 col-lg-10 col-sm-hide col-xs-hide">
                  <Chrono/>
                </div>
                <div className="col-md-hide col-lg-hide">
                  <ReactImage
                    img={{ src: ChronoMobile, alt: "chronology" }}
                    className="chronology-img"
                  />
                </div>
                <center>
                  <a
                    id="TalentsHereForYouCTA"
                    href="https://candidates.hiresweet.com"
                    className="hs-button--blue"
                  >
                    {t("talents_button")}
                  </a>
                </center>
              </div>
            </div>
          </section>

          {/* Testomony */}
          {/*
        <section id="HomeTestimony">
        <TestimonySlider
        testimonies={testimonies}
        settings={testimoniesSettings}
            />
          </section>
          */}
        </div>
      )}
    </Translation>
  );
}
