/**
 * Home page
 * @namespace MainView
 */

import React, { useLayoutEffect } from "react";
import Slider from "react-slick";
import { Translation } from "react-i18next";

import SliderBg from "../components/home/SliderBg";
import TestimonySlider from "../components/home/TestimonySlider";
import HomeSection from "../components/home/HomeSection";
import Kpi from "../components/home/Kpi";
import Integration from "../components/home/Integrations";
import AdaptableLink from "../components/AdaptableLink";

import { onClickFreeTrialButton } from "../components/ContactForm";
import ReactImage from "../components/ReactImage";
import { nl2br } from '../components/functions';

import { homeSections, START_DEMO_URL } from "../config/home/sections";
import { kpis } from "../config/home/kpis";
import { brands, brandsSettings } from "../config/home/brands";
import { testimonies, testimoniesSettings } from "../config/home/testimonies";
//import { press } from "../config/home/press";

/**
 * Display press logo with link
 * @function
 * @memberof MainView
 * @param {Press} press
 */
/*
function displayPressLogo(press, i) {
  const { img, url } = press;
  return (
    <a
      key={i}
      className="press-logo"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <ReactImage img={img} className="press-logo__img" />
    </a>
  );
}
*/

/**
 * Display brand logo
 * @function
 * @memberof MainView
 * @param {Brand} brand
 */
export function displayBrand(brand, i) {
  return (
    <div key={i}>
      <div style={{ marginTop: (80-(brand.height || 30))/2 }} >
        <ReactImage 
          img={brand} 
          className="home-brand__img" 
        />
      </div>
    </div>
  );
}
/**
 * Desktop / mobile viewport width breakpoint
 * @constant
 * @name BREAKPOINT
 * @memberof MainView
 * @type {number}
 */
const BREAKPOINT = 768;

// Real title
const title = React.createRef();
// White title
const effectTitle = React.createRef();
// Pink title
const effectTitleBis = React.createRef();
// Relative parent element
const homeHeader = React.createRef();

/**
 * Update effect title postion and width based on real title
 * @function
 * @name updateEffectTitle
 * @memberof MainView
 */
function updateEffectTitle() {
  const h1 = title.current;

  setEffectTitle(effectTitle.current, h1, homeHeader.current);
  setEffectTitle(effectTitleBis.current, h1, homeHeader.current);
}

/**
 * Set title css
 * @function
 * @name setEffectTitle
 * @memberof MainView
 * @param {HTMLElement} effectTitle - element to copy
 * @param {HTMLElement} title - Original element
 * @param {HTMLElement} wrapper - Element wrapper
 */
function setEffectTitle(effectTitle, title, wrapper) {
  const left =
    window.innerWidth > BREAKPOINT
      ? -(wrapper.offsetWidth / 2 - title.offsetLeft)
      : title.offsetLeft;

  effectTitle.style.width = `${title.offsetWidth}px`;
  effectTitle.style.height = `${title.offsetHeight}px`;
  effectTitle.style.left = `${left}px`;
  effectTitle.style.top = `${title.offsetTop}px`;
}

/**
 * Home page
 * @class
 * @name MainView
 * @memberof MainView
 * @return {ReactComponent}
 */
export default function({ history }) {
  useLayoutEffect(() => {
    // window.scrollTo(0);

    // Position on load
    let interval = setInterval(() => {
      updateEffectTitle();
    }, 10);
    setTimeout(() => {
      clearInterval(interval);
    }, 1000);

    // Change position on resize
    window.addEventListener("resize", updateEffectTitle);
    return () => {
      window.removeEventListener("resize", updateEffectTitle);
    };
  }, []);

  // Animation
  useLayoutEffect(() => {
    let section = document.getElementById("Section04");
    let img = section.getElementsByClassName("section-home__img")[0];

    import("lottie-web").then(({ default: lottie }) => {
      import("../config/home/HS_candidats.json").then(
        ({ default: jsonAnim }) => {
          img.querySelector("figure").classList.add("svg-anim");

          lottie.loadAnimation({
            container: img, // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: jsonAnim // the path to the animation json
          });
        }
      );
    });
  }, []);

  return (
    <Translation>
      {t => (
        <div id="MainView">
          <header id="HomeHeader" ref={homeHeader}>
            <SliderBg>
              <div className="title-subeffect--will-hide">
                <div ref={effectTitle} className="title-subeffect">
                  {t("home_title")}
                </div>
              </div>
              <div ref={effectTitleBis} className="title-subeffect-bis">
                {t("home_title")}
              </div>
            </SliderBg>

            <div className="wrapper">
              <div className="home-header">
                <div className="home-header__wrapper">
                  <h1 ref={title} className="home-header__title col-lg-11">
                    {t("home_title")}
                  </h1>

                  <p className="home-header__content col-lg-5 col-md-6">
                    {t("home_content")}
                  </p>

                  <div className="home-header__form">
                  <AdaptableLink
                    to={START_DEMO_URL}
                    onClick={onClickFreeTrialButton}
                  >
                    <button className="hs-button--submit">{t('book_demo')}</button>
                  </AdaptableLink>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* First home section */}
          <HomeSection
            key={homeSections[0].id}
            id={homeSections[0].id}
            title={t(homeSections[0].title)}
            button={{
              ...homeSections[0].button,
              text: t(homeSections[0].button.text)
            }}
            ChildNode={homeSections[0].ChildNode}
            content={nl2br(t(homeSections[0].content))}
          />

          {/* Brand slider */}
          <section id="HomeBrands">
            <div className="home-brand">
              <h3 className="home-title--sub home-brand__title">
                {t("brand_title")}
              </h3>
            </div>
            <div className="home-brand__slider">
              <Slider {...brandsSettings}>{brands.map(displayBrand)}</Slider>
            </div>
          </section>

          {/**
           * Display home sections
           *
           * @param {HomeSection} homeSection
           * @return {ReactComponent}
           */}
          {homeSections.slice(1).map(({ id, title, content, img, button }) => {
            return (
              <HomeSection
                key={id}
                id={id}
                title={t(title)}
                button={{
                  ...button,
                  text: t(button.text)
                }}
                img={img}
                content={t(content)}
              />
            );
          })}

          {/* Integrations */}
          <section id="HomeIntegrations" className="corner-top-right">
            <div className="wrapper">
              <div className="grid">
                <h2 className="home-title--sub home-integrations__title">
                  {t("integrations_title")}
                </h2>
                <Integration />
              </div>
            </div>
          </section>

          {/* KPIs */}
          <section id="HomeKpis" className="corner-top-right">
            <div className="wrapper">
              <div className="grid">
                <h2 className="hs-kpi__title">{t("kpi_main_title")}</h2>
                {kpis.map(({ title, content }) => {
                  return (
                    <div key={title} className="grid__item col-md-4 col-lg-4">
                      <Kpi title={t(title)} content={t(content)} />
                    </div>
                  );
                })}
              </div>

              {/* Demo request */}
              <AdaptableLink to={START_DEMO_URL} id='cta-kpis-section'>
                <button className="hs-button--submit">
                  {t("book_demo")}
                </button>
              </AdaptableLink>
            </div>
          </section>

          {/* Testomony */}
          <section id="HomeTestimony">
            <TestimonySlider
              testimonies={testimonies}
              settings={testimoniesSettings}
            />
          </section>

          {/* Press */}
          {/*
          <section id="HomePress" className="home-press">
            <div className="wrapper">
              <h2 className="home-title--sub home-press__title">
                {t("press_title")}
              </h2>

              <div className="home-press__logos">
                {press.map(displayPressLogo)}
              </div>
            </div>
          </section>
          */}
        </div>
      )}
    </Translation>
  );
}
