// import img01 from '../../assets/img/home/Section01-illus.png';
import img02 from '../../assets/img/home/Section02-illus.jpg';
import img03 from '../../assets/img/home/Section03-illus.png';
import img04 from '../../assets/img/home/Section04-illus.svg';
// import { freeTrialUrl } from './freeTrial';

import { ProfileScreen } from '../../components/platform/AppScreens';
import { onClickFreeTrialButton } from '../../components/ContactForm';

export const START_DEMO_URL = 'https://app.hiresweet.com/start-demo';

/**
 * @typedef {Object} HomeSection
 * 
 * @property {string} id - Html unique id 
 * @property {string} title - section title
 * @property {string} content - section content
 * @property {ButtonLink} [button={}] - section cta
 * @property {Image} img - section image
 * @property {string} [gridLeft=col-md-6 col-lg-6] - css class name for left column
 * @property {string} [gridRight=col-md-6 col-lg-6] - css class name for right column
 * @property {ReactComponent} [ChildNode] - replace image with SVG / JSX 
 * @property {string} [className=section-home] - css className 
 */

/**
 * Home sections 
 * @constant
 * @name homeSections
 * @memberof HomeData
 * @type {HomeSection[]}
 */ 
export const homeSections = [{
	id: 'Section01',
	title: 'section_01_title',
	content: 'section_01_content',
	ChildNode: ProfileScreen,
	button: {
		url: START_DEMO_URL,
		text: 'book_demo',
		onClick: onClickFreeTrialButton,
		id: 'first-section-cta',
	}
},{
	id: 'Section02',
	title: 'section_02_title',
	content: 'section_02_content',
	img: {
		src: img02,
		alt: ''
	},
	button: {
		url: START_DEMO_URL,
		text: 'book_demo',
		onClick: onClickFreeTrialButton,
		id: 'second-section-cta',
	}
},{
	id: 'Section03',
	title: 'section_03_title',
	content: 'section_03_content',
	img: {
		src: img03,
		alt: ''
	},
	button: {
		url: START_DEMO_URL,
		text: 'book_demo',
		onClick: onClickFreeTrialButton,
		id: 'third-section-cta',
	}
},{
	id: 'Section04',
	title: 'section_04_title',
	content: 'section_04_content',
	img: {
		src: img04,
		alt: ''
	},
	button: {
		url: START_DEMO_URL,
		text: 'book_demo',
		onClick: onClickFreeTrialButton,
		id: 'fourth-section-cta',
	}
}];