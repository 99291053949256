/* eslint-disable */
/**
 * Function for navbar / footer
 * @namespace FunctionsLayout
 */
import React from "react";
import { Translation } from "react-i18next";

/**
 * Display navbar items
 * @function
 * @name displayMenuItem
 * @memberof FunctionsLayout
 * @param {MenuItem} menuItem - Menu item meta data
 * @param {number} i - index
 * @return {ReactComponent} Menu item
 */
export function displayMenuItem(
  {
    name,
    url,
    extern,
    className,
    children,
    onMouseLeave,
    onMouseEnter,
    onClick
  },
  i
) {
  onMouseLeave = onMouseLeave || new Function("return;");
  onMouseEnter = onMouseEnter || new Function("return;");
  onClick = onClick || new Function("return;");
  return (
    <Translation key={i}>
      {(t) => {
        return (
          <li
            className={name === 'for_candidates' ? 'for-candidates': null }
            onMouseLeave={onMouseLeave}
            onMouseEnter={onMouseEnter}
            onClick={onClick}
          >
            {url ? (
              <a
                href={url}
                className={className ? className : ""}
                target={extern ? "_blank" : null}
                rel={extern ? "noopener noreferrer" : null}
              >
                {t(name)}
              </a>
            ) : (
              <span className={className ? className : ""}>{t(name)}</span>
            )}

            {/**
             * Nested elements
             */}
            {children && children.length > 0 ? (
              <ul className="nested-menu animation-done">
                {children.map(displayMenuItem)}
              </ul>
            ) : null}
          </li>
        );
      }}
    </Translation>
  );
}

/**
 * @interface SmartNav
 * @name SmartNav
 */
/**
 * Add class to navbar on scroll down / scroll up
 * @function
 * @name smartNav
 * @memberof FunctionsLayout
 * @param {Object} options
 * @param {number} options.delta - Scroll threshold
 * @param {number} options.navbarHeight - Navigation height
 * @param {HTMLElement} options.header - Navbar Element
 * @return {SmartNav} Start with init(), kill with destroy()
 */
export function smartNav(options) {
  const myOptions = {};

  myOptions.lastScrollTop = 0;
  myOptions.delta = options.delta || 5;
  myOptions.navbarHeight = options.navbarHeight || 0;
  myOptions.header = options.header || null;

  return {
    interval: null,
    didScroll: false,
    scrollOpt: myOptions,
    /**
     * Get document height
     * @function
     * @name SmartNav#docHeight
     * @return {number} Document height
     */
    docHeight: () => {
      var body = document.body,
        html = document.documentElement;

      var height = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );

      return height;
    },
    /**
     * Watch scroll
     * @function
     * @name SmartNav#setInterval
     */
    setInterval: function() {
      return setInterval(() => {
        if (this.didScroll) {
          this.hasScrolled();
          this.didScroll = false;
        }
      });
    },
    /**
     * Toggle navbar based on scroll
     * @function
     * @name SmartNav#hasScrolled
     */
    hasScrolled: function() {
      const st = window.scrollY;

      const scrollOpt = this.scrollOpt;

      // did not scroll enough
      if (Math.abs(scrollOpt.lastScrollTop - st) <= scrollOpt.delta) return;

      // bellow navbar
      if (st <= scrollOpt.navbarHeight) {
        scrollOpt.header.classList.add("on-top");
      } else {
        scrollOpt.header.classList.remove("on-top");
      }

      // If current position > last position AND scrolled past navbar...
      if (st > scrollOpt.lastScrollTop && st > scrollOpt.navbarHeight) {
        // Scroll Down
        scrollOpt.header.classList.remove("nav-down");
        scrollOpt.header.classList.add("nav-up");
      } else {
        // Scroll Up
        // If did not scroll past the document (possible on mac)...
        if (st + window.innerHeight < this.docHeight()) {
          scrollOpt.header.classList.remove("nav-up");
          scrollOpt.header.classList.add("nav-down");
        }
      }

      this.scrollOpt.lastScrollTop = st;
    },
    /**
     * Enable `hasScrolled`
     * @function
     * @name SmartNav#reset
     */
    reset: function() {
      this.didScroll = true;
    },
    /**
     * Initialize module
     * @function
     * @name SmartNav#init
     */
    init: function() {
      this.interval = this.setInterval();
      window.addEventListener("scroll", this.reset.bind(this));
    },
    /**
     * Reset/destroy module
     * @function
     * @name SmartNav#destroy
     */
    destroy: function() {
      window.removeEventListener("scroll", this.reset.bind(this));
      clearInterval(this.interval);
      this.interval = null;
    }
  };
}
