/**
 * Meta data for home page
 * @namespace HomeData
 */


import benchling from '../../assets/img/home/brands/benchling.svg';
import ycombinator from '../../assets/img/home/brands/ycombinator.svg';
import aircall from '../../assets/img/home/brands/aircall.svg';
import tandem from '../../assets/img/home/brands/tandem.svg';
import middesk from '../../assets/img/home/brands/middesk.svg';
import sqreen from '../../assets/img/home/brands/sqreen.svg';
import madkudu from '../../assets/img/home/brands/madkudu.svg';
import dashlane from '../../assets/img/home/brands/dashlane.svg';
import klickHealth from '../../assets/img/home/brands/klick-health.svg';
import brand from '../../assets/img/home/brands/brand.svg';
import potloc from '../../assets/img/home/brands/potloc.svg';
import jumbo from '../../assets/img/home/brands/jumbo.svg';
import spendesk from '../../assets/img/home/brands/spendesk.svg';
import gorgias from '../../assets/img/home/brands/gorgias.svg';


/*
   import blablacar from '../../assets/img/home/brands/blablacar.svg';
   import mindsay from '../../assets/img/home/brands/mindsay.svg';
   import drivy from '../../assets/img/home/brands/drivy.svg';
   import ledger from '../../assets/img/home/brands/ledger.svg';
   import nokia from '../../assets/img/home/brands/nokia.svg';
   import meetic from '../../assets/img/home/brands/meetic.svg';
   import meilleurs_agents from '../../assets/img/home/brands/meilleurs-agents.svg';
   import shapr from '../../assets/img/home/brands/shapr.svg';
   import lydia from '../../assets/img/home/brands/lydia.svg';
 */

/**
 * Brand logo
 * @typedef {Object} Brand
 * @property {String} src
 * @property {String} alt
 */

/**
 * Brands / partners
 * @constant
 * @name brands
 * @memberof HomeData
 * @type {Brand[]}
 */ 
export const brands = [
  { src: benchling, alt: 'benchling', height: 34 }, 
  { src: ycombinator, alt: 'y-combinator', height: 52}, 
  { src: aircall, alt: 'aircall', height: 40 }, 
  { src: tandem, alt:  'tandem', height: 40 }, 
  { src: middesk, alt:  'middesk', height: 24 }, 
  { src: sqreen, alt:  'sqreen', height: 31 }, 
  { src: madkudu, alt:  'madkudu', height: 41 }, 
  { src: dashlane, alt:  'dashlane', height: 35 }, 
  { src: klickHealth, alt:  'klick-health', height: 74 }, 
  { src: brand, alt:  'e-founders', height: 49 }, 
  { src: potloc, alt:  'potloc', height: 27 }, 
  { src: jumbo, alt:  'jumbo', height: 24 }, 
  { src: spendesk, alt:  'spendesk', height: 29 }, 
  { src: gorgias, alt:  'gorgias', height: 31 } 
];


/**
 * Brand slider options
 * @constant
 * @name brandsSettings
 * @memberof HomeData
 * @type {Object}
 */
export const brandsSettings = {
  arrows: false,
  dots: false,
  infinite: true,
  draggable: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  variableWidth: true,
  autoplay: true,
  speed: 3200,
  autoplaySpeed: 1,
  cssEase: "linear"
}; 
// export const brandsSettings = {
//   arrows: false,
//   dots: false,
//   infinite: true,
//   draggable: false,
//   slidesToShow: 10,
//   slidesToScroll: 1,
//   autoplay: true,
//   speed: 3200,
//   autoplaySpeed: 1,
//   cssEase: "linear",
//   responsive: [
//   	{
//   	  breakpoint: 1440,
//   	  settings: {
//   	    slidesToShow: 10,
//   	  }
//   	},
//   	{
//   	  breakpoint: 1330,
//   	  settings: {
//   	    slidesToShow: 9,
//   	  }
//   	},
//   	{
//   	  breakpoint: 1220,
//   	  settings: {
//   	    slidesToShow: 8,
//   	  }
//   	},
//     {
//       breakpoint: 1024,
//       settings: {
//         slidesToShow: 6,
//       }
//     },
//     {
//       breakpoint: 600,
//       settings: {
//         slidesToShow: 4,
//       }
//     },
//     {
//       breakpoint: 480,
//       settings: {
//         slidesToShow: 4,
//       }
//     }
//   ]
// };
