import React, { useEffect, Fragment } from "react";
import _ from "underscore";

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import MainHeader from "./layout/MainHeader";
import MainFooter from "./layout/MainFooter";
// Home page
import MainView from "./views/MainView";
// Platform page
import PlatformView from "./views/PlatformView";
// About page
import AboutView from "./views/AboutView";
// Talents page
import TalentsView from "./views/TalentsView";
// Technology page
import TechnologyView from "./views/TechnologyView";
// Contact page
import ContactView from "./views/ContactView";
// Login page
//import LoginView from "./views/LoginView";
// SecurityView
import SecurityView from "./views/SecurityView";

import NotFoundView from "./views/NotFoundView";

import { /*menu,*/ buttons } from "./config/navigation/navbar";

import { footer } from "./config/navigation/footer";

import { securitySections } from "./config/security/sections";
import { privacySections } from "./config/privacy/sections";
import { legalNoticeSections } from "./config/legalNotice/sections";

const loadHubspot = () => {
  try {
    (function unnamed() {
      const d = document;
      const s = d.createElement("script");
      s.src = "https://js.hs-scripts.com/1768483.js";
      s.async = 1;
      s.defer = 1;
      s.id = "hs-script-loader";
      s.type = "text/javascript";
      d.getElementsByTagName("body")[0].appendChild(s);
    })();
  } catch (e) {
    // console.error(e);
  }
};

/**
 * A React component
 * @typedef {React.Component} ReactComponent
 */

/**
 * Image meta data
 * @typedef {Object} Image
 * @property {string} src
 * @property {string} alt
 * @property {number} [width]
 * @property {number} [height]
 */

/**
 * Link with text
 * @typedef {Object} ButtonLink
 * @property {string} url
 * @property {string} [text]
 */

/**
 * Main component for SPA contains:
 * 1. Router with Switch
 * 2. Layout with navbar and footer
 *
 * @class
 * @name App
 * @return {ReactComponent}
 */
export default function() {
  if (window.DEBUG) {
    useEffect(() => {
      console.log("DEBUG: App has rendered!");
    }, []);
  }

  useEffect(() => {
    loadHubspot();
  }, []);

  return (
    <Fragment>
      {/*SPA header*/}

      <BrowserRouter>
        <header id="MainHeader">
          <Switch>
            <Route exact path="/talents">
              <MainHeader
                menuItems={[]}
                buttons={buttons.slice(3, 5)}
                logoRef={"https://candidates.hiresweet.com"}
              />
            </Route>
            <Route exact path="/candidates">
              <MainHeader
                menuItems={[]}
                buttons={buttons.slice(3, 5)}
                logoRef={"https://candidates.hiresweet.com"}
              />
            </Route>
            <Route>
              <MainHeader menuItems={[]} buttons={getItemsByName(buttons, ["book_demo"])} />
            </Route>
          </Switch>
        </header>

        <main>
          <Switch>
            <Route exact path="/" component={MainView} />
            <Route exact path="/platform">
              <PlatformView />
            </Route>
            <Route exact path="/about">
              <AboutView />
            </Route>
            <Route exact path="/talents">
              <Redirect to="/candidates" />
            </Route>
            <Route exact path="/candidates">
              <TalentsView />
            </Route>
            <Route exact path="/technology">
              <TechnologyView />
            </Route>
            <Route exact path="/contact" component={ContactView} />
            {/* <Route exact path="/login">
              <LoginView />
            </Route> */}
            <Route exact path="/security">
              <SecurityView
                sections={securitySections}
                title="security_title"
              />
            </Route>
            <Route exact path="/privacy">
              <SecurityView sections={privacySections} title="privacy_title" />
            </Route>
            <Route exact path="/legal-notice">
              <SecurityView
                sections={legalNoticeSections}
                title="legal_notice_title"
              />
            </Route>
            <Route component={NotFoundView} />
          </Switch>
        </main>

        <Switch>
          <Route exact path="/talents"></Route>
          <Route exact path="/candidates"></Route>
          <Route>
            <footer id="MainFooter">
              <MainFooter menuItems={footer} />
            </footer>
          </Route>
        </Switch>
      </BrowserRouter>
    </Fragment>
  );
}

const getItemsByName = (items, names) => {
  return _.map(names, (name) => _.findWhere(items, { name }));
};
