/**
 * @namespace Navigation
 */
 import { onClickFreeTrialButton } from "../../components/ContactForm";
/**
 * Menu item
 * @typedef {Object} MenuItem
 * @property {string} name - Display name
 * @property {string} url - Url
 * @property {boolean} extern - External link
 * @property {string} className - html class
 * @proterty {MenuItem[]} children - Nested menu
 */

/**
 * Navbar menu items
 * @constant
 * @name menu
 * @memberof Navigation
 * @type {MenuItem[]}
 */

export const menu = [
  {
    name: "for_candidates",
    url: "/candidates",
    children: [],
    className: "hs-button--for-candidates"
  },
  {
    name: "company_name",
    url: "/about",
    children: [
      {
        name: "mission",
        url: "/about"
      },
      {
        name: "story",
        url: "/about#AboutStory"
      },
      {
        name: "values",
        url: "/about#AboutValues"
      },
      {
        name: "team",
        url: "/about#HomeKpis"
      },
      {
        name: "hiring",
        url: "/about#AboutHire"
      }
    ]
  },
  {
    name: "technology",
    url: "/technology",
    children: [
      {
        name: "technology_knowledge",
        url: "/technology"
      },
      {
        name: "technology_reco",
        url: "/technology#TechnologySection02"
      },
      {
        name: "technology_custom",
        url: "/technology#TechnologySection04"
      },
      {
        name: "technology_fair",
        url: "/technology#TechnologySection05"
      },
      {
        name: "technology_privacy",
        url: "/technology#TechnologySection06"
      }
    ]
  },
  {
    name: "platform",
    url: "/platform",
    children: [
      {
        name: "platform_selec",
        url: "/platform"
      },
      {
        name: "platform_cv",
        url: "/platform#PlatformSection02"
      },
      {
        name: "platform_mix",
        url: "/platform#PlatformSection03"
      },
      {
        name: "platform_sys",
        url: "/platform#PlatformSection04"
      },
      {
        name: "platform_ats",
        url: "/platform#PlatformSection05"
      }
    ]
  },
  // {
  //   name: "talents",
  //   url: "/talents",
  //   children: [
  //     {
  //       name: "talents_get_contacted",
  //       url: "/talents"
  //     },
  //     {
  //       name: "talents_control",
  //       url: "/talents#TalentsTakeBack"
  //     },
  //     {
  //       name: "talents_hiw",
  //       url: "/talents#TalentsHiw"
  //     },
  //     {
  //       name: "talents_here",
  //       url: "/talents#TalentsHereForYou"
  //     }
  //   ]
  // },
  {
    name: "blog",
    url: "https://blog.hiresweet.com",
    extern: true
  }
];

/**
 * Navbar buttons
 * @constant
 * @name buttons
 * @memberof Navigation
 * @type {MenuItem[]}
 */

export const buttons = [
  {
    name: "sign_in",
    url: "https://app.hiresweet.com",
    extern: false,
    className: "hs-button--demo"
  },
  {
    name: "book_demo",
    url: "https://app.hiresweet.com/start-demo",
    extern: true,
    className: "hs-button--demo",
    onClick: onClickFreeTrialButton
  },
  // {
  //   name: "try_free_trial",
  //   url: "https://internal-beta.hiresweet.com/free-trial",
  //   extern: true,
  //   className: "hs-button--demo",
  //   onClick: onClickFreeTrialButton
  // },
  /*
  {
    name: "sign_in_2",
    url: "https://candidates.hiresweet.com",
    extern: false,
    className: "hs-button--signin"
  },
  */
  {
    name: "for_employers",
    url: "/",
    extern: false,
    className: "hs-button--for-employers"
  },
  {
    name: "talents_signin",
    url: "https://candidates.hiresweet.com",
    extern: false,
    className: "hs-button--demo"
  },
  // {
  //   name: "for_candidates",
  //   url: "/talents",
  //   extern: false,
  //   className: "hs-button--for-candidates"
  // },
];
