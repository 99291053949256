/* eslint-disable */
/**
 * Navigation bar
 * @namespace MainHeader
 */

import React, { useState, useEffect } from "react";
import { Translation } from "react-i18next";
import _ from "underscore";

import LanguageSelection from "../components/LanguageSelection";
import ReactImage from "../components/ReactImage";
import { displayMenuItem, smartNav } from "./functions";

import In from "../assets/img/layout/in-bl.svg";
import fb from "../assets/img/layout/fb-bl.svg";
import medium from "../assets/img/layout/medium-bl.svg";
import tw from "../assets/img/layout/tw-bl.svg";
import { useQuery } from "../functions";

// Time out for menu appearance
let timeout = null;

/**
 * Time after which nested menu disapears
 * @name TIMEOUT_THRES
 * @memberof MainHeader
 * @constant
 * @type {number}
 */

// const TIMEOUT_THRES = 650;
const TIMEOUT_THRES = 250;

/**
 * Affix breakpoint
 * @name NAVBAR_HIDE
 * @memberof MainHeader
 * @constant
 * @type {number}
 */

const NAVBAR_HIDE = 20;

/**
 * @typedef {Object} Social
 *
 * @property {Image} img
 * @property {String} url
 */

/**
 * Social links
 * @name socials
 * @memberof MainHeader
 * @constant
 * @type {Social[]}
 */

const socials = [
  {
    img: {
      src: medium,
      alt: "Medium"
    },
    url: "https://blog.hiresweet.com"
  },
  {
    img: {
      src: fb,
      alt: "facebook"
    },
    url: "https://www.facebook.com/hiresweet/"
  },
  {
    img: {
      src: tw,
      alt: "Twitter"
    },
    url: "https://twitter.com/hiresweet_"
  },
  {
    img: {
      src: In,
      alt: "LinkedIn"
    },
    url: "https://www.linkedin.com/company/hiresweet/"
  }
];

/**
 * Open / close mobile navbar
 * @function
 * @memberof MainHeader
 * @param {function} setter - state modifier
 * @param {*} currentState - state
 * @return {function} onclick event handler
 */
function toggleNavbar(setter, currentState) {
  return evt => {
    evt.preventDefault();
    setter(!currentState);
  };
}

/**
 * SPA header contains
 * 1. Navbar
 *
 * @class
 * @name MainHeader
 * @memberof MainHeader
 * @param {MenuItem[]} menuItems
 * @return {ReactComponent}
 */
export default function({ menuItems, buttons, logoRef }) {
  const [navbarOpen, setNavState] = useState(false);
  const [navbarAffix, setAffix] = useState(false);
  const [selectedMenu, setMenu] = useState({
    items: [],
    x: 0,
    y: 0
  });

  const navbar = React.createRef();

  useEffect(() => {
    if (navbarOpen) document.body.classList.add("navbar-open");
    else document.body.classList.remove("navbar-open");
  }, [navbarOpen]);

  // Affix on / off
  useEffect(() => {
    window.addEventListener("scroll", function(evt) {
      if (window.scrollY > NAVBAR_HIDE && !navbarAffix) setAffix(true);
      if (window.scrollY <= NAVBAR_HIDE && navbarAffix) setAffix(false);
    });
  }, [navbarAffix]);

  // Auto hide navbar
  useEffect(() => {
    const nav = new smartNav({
      navbarHeight: navbar.current.offsetHeight,
      header: navbar.current
    });

    nav.init();
  }, []);

  const logoReference = logoRef || "/";

  const queryParams = useQuery();
  const freeTrialMode = !!queryParams.get("free-trial");
  const displayedButtons = freeTrialMode
    ? _.filter(buttons, ({ name }) => name !== "book_demo")
    : _.filter(buttons, ({ name }) => name !== "try_free_trial");
  return (
    <div id="MainNavbar" ref={navbar} className={navbarAffix ? "affix" : ""}>
      <a className="hmbg" onClick={toggleNavbar(setNavState, navbarOpen)}>
        <span></span>
        <span></span>
        <span></span>
      </a>

      {/* Mobile logo */}
      <a className="mobile-logo logo logo-white" href={logoReference}></a>

      <nav className="navbar">
        <div className="wrapper-navbar">
          {/* Desktop logo */}
          <a className="logo navbar__logo" href={logoReference}></a>
          {/* Static cross */}
          {/*<a className = 'navbar__close'
						  onClick = {toggleNavbar(setNavState, navbarOpen)}></a>*/}

          <ul className="navbar-menu">
            {/**
             * Set onmouseleave event handler for fade in /out
             * @param {MenuItem} menuItem - menu item
             * @param {Number} i - index
             */}
            {menuItems.map((menuItem, i) => {
              // Nested menu fade in / out
              if (menuItem.children && menuItem.children.length > 0) {
                // Nested menu fades out
                menuItem.onMouseLeave = function(evt) {
                  if (timeout) clearTimeout(timeout);

                  let li = evt.currentTarget;
                  let nestedMenu = li.querySelector(".nested-menu");

                  timeout = setTimeout(() => {
                    setMenu({
                      items: [],
                      x: selectedMenu.x,
                      y: selectedMenu.y
                    });
                    // nestedMenu.classList.add('animation-done');
                  }, TIMEOUT_THRES);
                };
                // Nested menu fades in
                menuItem.onMouseEnter = function(evt) {
                  if (timeout) clearTimeout(timeout);

                  let li = evt.currentTarget.getBoundingClientRect();

                  setMenu({
                    items: menuItem.children,
                    x: li.left,
                    y: li.top
                  });
                };
              }
              return displayMenuItem(menuItem, i);
            })}
          </ul>

          <div className="navbar-buttons">
            <ul>{displayedButtons.map(displayMenuItem)}</ul>
          </div>

          <div className="navbar-lang">
            <span className="navbar__item">
              <Translation>{t => t("language")}</Translation>
            </span>
            <LanguageSelection />
          </div>

          <div className="navbar__socials">
            {/* Social icons */}
            <ul className="footer__socials">
              {socials.map(({ img, url }, i) => {
                return (
                  <li key={i}>
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      <ReactImage img={img} />
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div
            id="MovingMenu"
            style={{
              position: "fixed",
              width: "0",
              backgroundColor: "#fff",
              top: "42px",
              left: selectedMenu.x,
              transition: ".3s all",
              opacity: selectedMenu.items.length > 0 ? "1" : "0"
            }}
            onMouseLeave={evt => {
              if (timeout) clearTimeout(timeout);

              timeout = setTimeout(() => {
                setMenu({
                  items: [],
                  x: selectedMenu.x,
                  y: selectedMenu.y,
                });
                // nestedMenu.classList.add('animation-done');
              }, TIMEOUT_THRES);
            }}
            onMouseEnter={evt => {
              if (timeout) clearTimeout(timeout);
            }}
          >
            <ul className="nested-menu">
              {selectedMenu.items.map(displayMenuItem)}
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
